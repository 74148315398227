import type { OpenAPIObject } from 'openapi3-ts';

import { JSON_OPENAPI_DOCS } from '../constants';
import useFetch from './lib/useFetch';

import type { UseFetchConfig } from './lib/useFetch';


const useOpenApiDocs = (config?: UseFetchConfig) => useFetch<OpenAPIObject>(JSON_OPENAPI_DOCS, config);

export default useOpenApiDocs;
