import * as React from 'react';

import Box from '@appbuckets/react-ui/Box';
import Button from '@appbuckets/react-ui/Button';
import Label from '@appbuckets/react-ui/Label';

import { API_HOST, JSON_OPENAPI_DOCS } from '../../../constants';

import { useDocs } from '../docs.context';
import DocsSection from '../molecules/DocsSection';


const DocsTitle: React.VFC = () => {

  const { docs } = useDocs();


  // ----
  // Component Render
  // ----
  return (
    <DocsSection
      title={docs.rootDoc.info.title}
      description={(
        <React.Fragment>
          <Label content={`Versione ${docs.rootDoc.info.version}`} />

          <Box mt={6}>
            <div>
              <Button
                primary
                className={'mr-4'}
                rel={'noreferrer'}
                target={'_blank'}
                href={`${API_HOST}/${JSON_OPENAPI_DOCS}`}
                icon={'download'}
              />
              <span>Scarica le specifiche OpenAPI</span>
            </div>
          </Box>

          <Box mt={4}>
            <div>
              <Button
                href={'mailto:info@proedis.net'}
                icon={'paper-plane'}
              />
              <span>Contatta il team di Sviluppo per ricevere Supporto</span>
            </div>
          </Box>
        </React.Fragment>
      )}
    />
  );
};

export default DocsTitle;
