import * as React from 'react';

import Section from '@appbuckets/react-ui/Section';
import TocLink from '../atoms/TocLink';

import type { TOCSection } from '../organism/DocsToc';


const TocSection: React.VFC<TOCSection> = (props) => {

  const { label, elements } = props;


  // ----
  // Build Links
  // ----
  const tocLinks = elements.map((element) => (
    <TocLink
      key={element.hash}
      {...element}
    />
  ));


  // ----
  // Render Section if Exists
  // ----
  if (label) {
    return (
      <Section label={label}>
        {tocLinks}
      </Section>
    );
  }


  return (
    <ul>
      {tocLinks}
    </ul>
  );
};

export default TocSection;
