import * as React from 'react';
import clsx from 'clsx';

import Header from '@appbuckets/react-ui/Header';
import Label from '@appbuckets/react-ui/Label';
import Section from '@appbuckets/react-ui/Section';

import DocsSectionCode from '../atoms/DocsSectionCode';

import DocsSectionContent from '../atoms/DocsSectionContent';

import { useDocs } from '../docs.context';


/* --------
 * Component Props
 * -------- */
export interface DocsSectionProps {
  /** Add content to code section */
  code?: React.ReactNode;

  /** Main Content Description */
  description?: React.ReactNode;

  /** Manually set hash */
  hash?: string;

  /** Set doc section level */
  level?: number;

  /** For request, set the proper method */
  method?: string;

  /** TOC Order */
  tocOrder?: number;

  /** The section title */
  title: string;
}


/* --------
 * Component Definition
 * -------- */
const DocsSection: React.VFC<DocsSectionProps> = (props) => {

  const {
    code,
    description,
    hash: manualHash,
    level,
    method,
    title,
    tocOrder
  } = props;


  // ----
  // Internal Hooks
  // ----
  const { addToGeneralTOC } = useDocs();


  // ----
  // Add to TOC and Retrieve Hash
  // ----
  let hash: string | undefined;
  if (typeof tocOrder === 'number') {
    hash = addToGeneralTOC(title, tocOrder);
  }

  const classes = clsx(
    'docs-section',
    level && `level-${level}`,
    method && 'is-request'
  );


  // ----
  // Component Render
  // ----
  return (
    <div id={manualHash || hash} className={classes}>
      <DocsSectionContent>
        <Header
          content={method
            ? (
              <Section label={'Endpoint'}>
                <Label
                  size={'small'}
                  success={method === 'GET'}
                  danger={method === 'DELETE'}
                  primary={method === 'POST'}
                  secondary={method === 'PUT'}
                  content={method}
                  className={'mr-4'}
                />
                <span className={'has-text-cloud'}>https://talker.cantieridigitali.net</span>{title}
              </Section>
            )
            : title}
        />
        {description}
      </DocsSectionContent>
      {code && (
        <DocsSectionCode>
          {code}
        </DocsSectionCode>
      )}
    </div>
  );

};

export default DocsSection;
