import arraySort from 'array-sort';
import { OpenApiBuilder, ReferenceObject, RequestBodyObject, SchemaObject } from 'openapi3-ts';

import { mock } from 'mock-json-schema';

import type {
  OpenAPIObject,
  OperationObject,
  ResponseObject
} from 'openapi3-ts';

import type { ApiKey } from '../../../interfaces/ApiKey';
import type { TOCSection, TOCElement } from '../organism/DocsToc';


/* --------
 * Internal Types
 * -------- */
type ForEachPathCallback = (operation: OperationObject, path: string, method: string) => void;

type PathMethods = { name: string, path: string, methods: string[] };

type Response = {
  statusCode: number;
  description: string;
  type: string;
  example: any;
  schema: any;
};


/* --------
 * Class Extension
 * -------- */
export default class OpenAPIDocs extends OpenApiBuilder {

  /**
   * Create a new OpenAPIDocs Instance
   * @param docs The main JSON Document
   * @param apiKey The ApiKey object
   */
  constructor(docs: OpenAPIObject, private readonly apiKey: ApiKey | null) {
    /** Instantiate super class */
    super(docs);
  }


  /**
   * Execute a callback function fo each defined API Endpoint
   * the callback function will receive the OperationObject as first params
   * the complete path and the method
   * @param callback
   */
  forEachPath(callback: ForEachPathCallback) {
    /** Abort if no path exists */
    if (!this.rootDoc.paths) {
      return;
    }

    /** Loop each paths */
    Object.keys(this.rootDoc.paths).forEach((path) => {
      /** Get the definition */
      const definition = this.rootDoc.paths[path];

      /** Abort if not an object */
      if (typeof definition !== 'object' || Array.isArray(definition) || definition === null) {
        return;
      }

      /** Loop each method */
      Object.keys(definition).forEach((method) => {
        const operation: OperationObject | undefined = definition[method];

        if (typeof operation !== 'object' || Array.isArray(operation) || operation === null) {
          return;
        }

        /** Call the callback */
        callback(operation, path, method);
      });
    });
  }


  /**
   * Get the operation object for a specific path and a method
   * @param path
   * @param method
   */
  getEndPoint(path: string, method: string): OperationObject | undefined {
    return this.rootDoc.paths[path]?.[method];
  }


  /**
   * Get all responses object type for a specific endpoint object
   * @param endpoint
   */
  getResponses(endpoint: OperationObject) {
    /** Create the responses container */
    const result: Response[] = [];

    /** Assert the main responses object exists and is valid */
    if (typeof endpoint.responses !== 'object' || endpoint.responses === null) {
      return result;
    }

    /** Loop each documented response */
    Object.keys(endpoint.responses).forEach((statusCode) => {
      /** Get the response object */
      const response = endpoint.responses[statusCode] as ResponseObject;

      /** Assert response object is valid */
      if (typeof response.content !== 'object' || response.content === null) {
        return;
      }

      Object.keys(response.content).forEach((contentType) => {
        /** Check a response has been defined for this specific content type */
        if (!response.content![contentType]?.schema) {
          return;
        }

        /** Cast the schema */
        const responseSchema = response.content![contentType].schema as SchemaObject | ReferenceObject;

        /** Save the ref path */
        let isArray: boolean = false;
        let $mainRef: string;

        /** Check if current defined schema is an array */
        if ((responseSchema as SchemaObject).type === 'array') {
          /** Save response is an Array */
          isArray = true;
          /** Save the ref */
          $mainRef = ((responseSchema as SchemaObject).items as ReferenceObject)?.$ref;
        }
        else {
          /** Schema is not an Array but is directly a reference object */
          $mainRef = (responseSchema as ReferenceObject).$ref;
        }

        /** Get the Response Schema */
        const componentSchema = this.getComponentSchema($mainRef);
        const schema = isArray
          ? { type: 'array', items: componentSchema }
          : componentSchema;

        /** Mock the Schema */
        let schemaMocked: any = '';

        try {
          schemaMocked = mock(schema);
        }
        catch (e) {
          window.console.error({
            message: 'Error mocking schema',
            response,
            schema
          });
        }

        /** Add to responses */
        result.push({
          statusCode : +statusCode,
          description: response.description,
          type       : contentType,
          example    : schemaMocked,
          schema
        });
      });
    });

    return result;
  }


  getRequestBody(endpoint: OperationObject) {
    /** Extract the request body content */
    const bodyContentSchema = this.getCompiledSchema(
      (endpoint.requestBody as RequestBodyObject | undefined)?.content?.['application/json']
        .schema as SchemaObject | ReferenceObject
    );

    /** Assert it exists */
    if (!bodyContentSchema) {
      return undefined;
    }

    /** Mock the Schema */
    let schemaMocked: any = '';

    try {
      schemaMocked = mock(bodyContentSchema);
    }
    catch (e) {
      window.console.error({
        message: 'Error mocking schema',
        endpoint,
        bodyContentSchema
      });
    }

    return {
      example: schemaMocked,
      schema : bodyContentSchema
    };
  }


  private getCompiledSchema(schema: SchemaObject | ReferenceObject | undefined) {
    if (!schema) {
      return undefined;
    }

    /** Save the ref path */
    let isArray: boolean = false;
    let $mainRef: string;

    /** Check if current defined schema is an array */
    if ((schema as SchemaObject).type === 'array') {
      /** Save response is an Array */
      isArray = true;
      /** Save the ref */
      $mainRef = ((schema as SchemaObject).items as ReferenceObject)?.$ref;
    }
    else {
      /** Schema is not an Array but is directly a reference object */
      $mainRef = (schema as ReferenceObject).$ref;
    }

    const componentSchema = this.getComponentSchema($mainRef);
    return isArray
      ? { type: 'array', items: componentSchema }
      : componentSchema;
  }


  /**
   * Return a schema object at specific path
   * resolving nested $ref
   * @param path
   * @param iteration
   */
  // eslint-disable-next-line class-methods-use-this
  getComponentSchema(path?: string, iteration: number = 0): SchemaObject | undefined {
    /** Assert iteration is not more then 5 */
    if (iteration > 5) {
      return;
    }

    /** Assert path is a valid string */
    if (typeof path !== 'string' || !path.length || path.charAt(0) !== '#') {
      return;
    }

    /** Get the component Complete Name */
    const objectName = path.substring(path.lastIndexOf('/') + 1);
    const schema = this.rootDoc.components?.schemas?.[objectName];

    /** Check object exists */
    if (typeof schema !== 'object' || schema == null) {
      return;
    }

    /** If is $ref object, resolve it */
    if ((schema as ReferenceObject).$ref) {
      return this.getComponentSchema((schema as ReferenceObject).$ref, iteration + 1);
    }

    /** Cast variable */
    const objectSchema: SchemaObject = schema as SchemaObject;

    /** If objectSchema is an array type, resolve items and return */
    if (objectSchema.type === 'array') {
      /** Get items ref */
      const $itemsRef = (objectSchema.items as ReferenceObject)?.$ref;
      /** Get items schema */
      const itemsSchema = this.getComponentSchema($itemsRef, iteration + 1);
      /** Assert items schema exists before return */
      return itemsSchema
        ? { ...objectSchema, type: 'array', items: itemsSchema }
        : undefined;
    }

    /** If object doesn't has any properties, return it */
    if (typeof objectSchema.properties !== 'object' || objectSchema.properties == null) {
      return objectSchema;
    }

    /** Loop each object properties */
    Object.keys(objectSchema.properties).forEach((propertyName) => {
      /** Get the property */
      const property = objectSchema.properties![propertyName] as SchemaObject | ReferenceObject;

      /** If property is a plain ref object, resolve ref path */
      if ((property as ReferenceObject).$ref) {
        /** Resolve property schema */
        const propertySchema = this.getComponentSchema((property as ReferenceObject).$ref, iteration + 1);
        /** Assert exists before change type */
        if (propertySchema) {
          objectSchema.properties![propertyName] = propertySchema;
        }
        /** Abort loop */
        return;
      }

      /** Cast property */
      const propertySchema = property as SchemaObject;

      /** If property type is an array, resolve items schema */
      if (propertySchema.type === 'array') {
        /** Get items ref */
        const $itemsRef = (propertySchema.items as ReferenceObject)?.$ref;
        /** Resolve the items schema */
        const itemsSchema = this.getComponentSchema($itemsRef, iteration + 1);
        /** Assert item schema exists before change type */
        if (itemsSchema) {
          objectSchema.properties![propertyName] = {
            ...objectSchema.properties![propertyName],
            type : 'array',
            items: itemsSchema
          };
        }
      }
    });

    return objectSchema;
  }


  getTocSections(): TOCSection[] {
    const result: TOCSection[] = [];

    this.getTags().forEach((tag) => {
      const elements: TOCElement[] = [];

      this.getPathForTags(tag).forEach((desc) => {
        /** Check if user could see doc */
        if (this.apiKey?.allowAllActionByDefault
          || this.apiKey?.actionLimits.some((limit) => limit.controller === tag && limit.action === desc.name && limit.enabled)) {
          elements.push({
            title  : desc.name,
            hash   : desc.path,
            methods: desc.methods
          });
        }
      });

      /** Push the tag only if contains some elements */
      if (elements.length) {
        result.push({
          label      : tag,
          description: this.rootDoc.tags?.find((t) => t.name === tag)?.description || '',
          elements   : arraySort(elements, [ 'title' ])
        });
      }
    });

    return arraySort(result, [ 'label' ]);
  }


  getTags(): string[] {
    const result: string[] = [];

    this.forEachPath((operation) => {
      operation.tags?.forEach((tag) => {
        if (result.indexOf(tag) === -1) {
          result.push(tag);
        }
      });
    });

    return result;
  }


  getPathForTags(tag: string): PathMethods[] {
    const result: PathMethods[] = [];

    this.forEachPath((operation, path, method) => {
      if (!Array.isArray(operation.tags) || operation.tags.indexOf(tag) === -1) {
        return;
      }

      const existingItem = result.find(r => r.path === path);

      if (existingItem) {
        existingItem.methods.push(method.toUpperCase());
      }
      else {
        result.push({ name: operation.operationId || 'Unnamed', path, methods: [ method.toUpperCase() ] });
      }
    });

    return result;
  }

}
