import * as React from 'react';

import type { OpenAPIObject } from 'openapi3-ts';
import { useApiKey } from '../../context/apiKey.context';

import type { UseFetchResult } from '../../hooks/lib/useFetch';

import { OpenAPIDocsProvider } from './docs.context';

import DocsApi from './organism/DocsApi';
import DocsAuthentication from './organism/DocsAuthentication';
import DocsIntroduction from './organism/DocsIntroduction';

import DocsSidebar from './organism/DocsSidebar';
import DocsSpecification from './organism/DocsSpecification';
import DocsTitle from './organism/DocsTitle';


/* --------
 * Component Props
 * -------- */
interface DocsProps {
  apiDocs: UseFetchResult<OpenAPIObject>;
}


/* --------
 * Hook Definition
 * -------- */
const Docs: React.VFC<DocsProps> = (props) => {

  const { apiDocs } = props;

  const apiKey = useApiKey();


  // ----
  // No data fallback
  // ----
  if (!apiDocs.data) {
    return null;
  }


  // ----
  // Component Render
  // ----
  return (
    <OpenAPIDocsProvider apiKey={apiKey} docsObject={apiDocs.data}>
      <div id={'docs'}>
        <div id={'toc-sidebar'}>
          <DocsSidebar />
        </div>
        <div id={'docs-content'}>
          <DocsTitle />
          <DocsIntroduction />
          <DocsSpecification />
          <DocsAuthentication />
          <DocsApi />
        </div>
      </div>
    </OpenAPIDocsProvider>
  );
};

export default Docs;
