import * as React from 'react';


/* --------
 * Component Props
 * -------- */
export interface DocsSectionContentProps {

}


/* --------
 * Component Definition
 * -------- */
const DocsSectionContent: React.FC<DocsSectionContentProps> = (props) => {

  const { children } = props;

  return (
    <div className={'docs-section-content'}>
      {children}
    </div>
  );
};

export default DocsSectionContent;
