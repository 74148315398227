export const ASSETS_URL = 'https://cdn.proedis.net/images/proedis/logo';

export const API_HOST = process.env.NODE_ENV === 'development'
  ? 'https://localhost:5001'
  : 'https://talker.cantieridigitali.net';


export const JSON_OPENAPI_DOCS = 'api-docs/v1/docs.json';

export const OPENAPI_SPECIFICATION_URL = 'https://swagger.io/specification/';
