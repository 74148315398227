import * as React from 'react';

import axios, { AxiosError } from 'axios';

import { API_HOST } from '../../constants';

/* --------
 * Internal Types
 * -------- */
export interface UseFetchConfig {
  autoStart?: boolean;
}


/* --------
 * Response Type
 * -------- */
interface ProblemDetails {
  detail: string;

  instance: string;

  status: number;

  title: string;

  type: string;
}


/* --------
 * Result Types
 * -------- */
export type UseFetchResult<Response> = UseFetchResultState<Response> & UseFetchResultHelper;

export type UseFetchResultState<Response> = UseFetchResultVariable<Response> & UseFetchResultIndependent;

export type UseFetchResultVariable<Response> =
  | UseFetchResultLoading
  | UseFetchResultError
  | UseFetchResultSuccess<Response>;

export interface UseFetchResultHelper {
  fetch: () => void;
}

export interface UseFetchResultIndependent {
  fetching: boolean;
}

export interface UseFetchResultLoading {
  data: null;

  error: null;

  loaded: false;
}

export interface UseFetchResultError {
  data: null;

  error: ProblemDetails;

  loaded: false;
}

export interface UseFetchResultSuccess<Response> {
  data: Response;

  error: null;

  loaded: true;
}


/* --------
 * Main Hook Definition
 * -------- */
export default function useFetch<Response>(url: string, config?: UseFetchConfig): UseFetchResult<Response> {

  // ----
  // Get Configuration
  // ----
  const {
    autoStart = true
  } = config || {};

  // ----
  // Internal State
  // ----
  const [ state, setState ] = React.useState<UseFetchResultState<Response>>({
    data    : null,
    error   : null,
    fetching: !!autoStart,
    loaded  : false
  });


  // ----
  // Main Fetch Function
  // ----
  const fetchData = React.useCallback(
    async () => {
      /** Set the isFetching State */
      setState((curr) => ({ ...curr, fetching: true }));

      /** Use fetch to perform the request */
      try {
        const response = await axios.get<Response>(`${API_HOST}/${url}`, { responseType: 'json' });
        setState({
          data    : response.data,
          error   : null,
          fetching: false,
          loaded  : true
        });
      }
      catch (fetchError: any) {
        const error = ((fetchError as AxiosError).response?.data || {
          detail  : 'Internal Error',
          instance: url,
          status  : 500,
          title   : 'client/internal-error',
          type    : 'https://tools.ietf.org/html/rfc7231#section-6.6.1'
        }) as ProblemDetails;

        setState({
          data    : null,
          error,
          fetching: false,
          loaded  : false
        });
      }
    },
    [ url ]
  );


  // ----
  // Launch Request
  // ----
  React.useEffect(
    () => {
      if (autoStart) {
        fetchData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );


  // ----
  // Return Result
  // ----
  return React.useMemo(
    () => ({
      ...state,
      fetch: fetchData
    }),
    [ state, fetchData ]
  );
}
