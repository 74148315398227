import * as React from 'react';

import Fade from '@appbuckets/react-ui/Fade';

import DocsLogin from './atoms/DocsLogin';
import Docs from './components/Docs';
import { ApiKeyContext } from './context/apiKey.context';

import useOpenApiDocs from './hooks/useOpenApiDocs';

import type { ApiKey } from './interfaces/ApiKey';


const App: React.VFC = () => {

  // ----
  // Internal Hooks
  // ----
  const docs = useOpenApiDocs();


  // ----
  // Internal State
  // ----
  const [ visible, setVisible ] = React.useState<'login' | 'docs'>();
  const [ apiKey, setApiKey ] = React.useState<ApiKey>();


  React.useEffect(
    () => {
      setVisible(undefined);

      setTimeout(() => {
        if (apiKey) {
          document.body.classList.add('logged');
        }
        else {
          document.body.classList.remove('login');
        }
        setVisible(apiKey ? 'docs' : 'login');
      }, 500);
    },
    [ apiKey ]
  );


  // ----
  // Component Render
  // ----
  return (
    <React.Fragment>
      <Fade
        appear
        visible={visible === 'login'}
        timeout={175}
        onEnter={undefined}
        onEntering={undefined}
        onEntered={undefined}
        onExit={undefined}
        onExiting={undefined}
        onExited={undefined}
      >
        <div>
          <DocsLogin onApiKeyLoaded={setApiKey} />
        </div>
      </Fade>

      <Fade
        appear
        visible={visible === 'docs' && !!docs.data}
        timeout={175}
        onEnter={undefined}
        onEntering={undefined}
        onEntered={undefined}
        onExit={undefined}
        onExiting={undefined}
        onExited={undefined}
      >
        <div>
          <ApiKeyContext.Provider value={apiKey}>
            <Docs apiDocs={docs} />
          </ApiKeyContext.Provider>
        </div>
      </Fade>
    </React.Fragment>
  );

};

export default App;
