import * as React from 'react';

import Label from '@appbuckets/react-ui/Label';

import type { TOCElement } from '../organism/DocsToc';


const TocLink: React.VFC<TOCElement> = (props) => {

  const { title, hash, methods } = props;


  // ----
  // Render without methods
  // ----
  if (!methods.length) {
    return (
      <li>
        <a href={`#${hash}`}>
          <span>{title}</span>
        </a>
      </li>
    );
  }


  // ----
  // Render as Collapsable Element
  // ----
  return (
    <ul>
      {[ 'GET', 'POST', 'PUT', 'DELETE' ].map((method) => {
        if (methods.indexOf(method) === -1) {
          return null;
        }

        return (
          <li key={method}>
            <a href={`#${method}--${hash}`}>
              <span>
                <Label
                  success={method === 'GET'}
                  danger={method === 'DELETE'}
                  primary={method === 'POST'}
                  secondary={method === 'PUT'}
                  content={method}
                />
                {hash}
              </span>
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default TocLink;
