import * as React from 'react';

import { useDocs } from '../docs.context';
import TocSection from '../molecules/TocSection';


export type TOCElement = { title: string, hash: string, methods: string[] };

export type TOCSection = { label: string, description: string, elements: TOCElement[] };


const DocsToc: React.VFC = () => {

  const { docs, generalTOC } = useDocs();


  // ----
  // Build TOC Items
  // ----
  const sections: TOCSection[] = [];

  sections.push({
    label      : '',
    description: '',
    elements   : generalTOC.map(t => ({
      title  : t.title,
      hash   : t.hash,
      methods: []
    }))
  });


  // ----
  // Automatically Build TOC using Docs
  // ----
  sections.push(...docs.getTocSections());


  // ----
  // Component Render
  // ----
  return (
    <React.Fragment>
      {sections.map((section) => (
        <TocSection
          key={section.label || 'unlabeled'}
          {...section}
        />
      ))}
    </React.Fragment>
  );
};

export default DocsToc;
