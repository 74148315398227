import * as React from 'react';

import Input from '@appbuckets/react-ui/Input';
import type { InputProps } from '@appbuckets/react-ui/Input';

import { useDocs } from '../docs.context';
import { ASSETS_URL } from '../../../constants';

import DocsToc from './DocsToc';


/* --------
 * Component Definition
 * -------- */
const DocsSidebar: React.VFC = () => {

  // ----
  // Internal Hooks
  // ----
  const { search, setSearch } = useDocs();


  // ----
  // Handlers
  // ----
  const handleSearchChange = React.useCallback(
    (e: React.ChangeEvent, inputProps: InputProps) => {
      setSearch(inputProps.value || '');
    },
    [ setSearch ]
  );


  // ----
  // Component Render
  // ----
  return (
    <React.Fragment>
      {/* Logo */}
      <div className={'logo'}>
        <img src={`${ASSETS_URL}/full_256.png`} alt={'Proedis TextMark'} />
      </div>

      {/* Search */}
      <div className={'filter'}>
        <Input
          clearable={!!search}
          placeholder={'Filtra...'}
          onChange={handleSearchChange}
        />
      </div>

      {/* Toc */}
      <div className={'toc'}>
        <DocsToc />
      </div>
    </React.Fragment>
  );

};

export default DocsSidebar;
