import * as React from 'react';

import DocsSection from '../molecules/DocsSection';


const DocsIntroduction: React.VFC = () => (
  <DocsSection
    tocOrder={0}
    title={'Introduzione'}
    description={(
      <p>
        Questo documento riassume e definisce le specifiche di Autenticazione e di{' '}
        utilizzo del WebService <b>YardTalker</b> per comunicare autonomamente con la piattaforma{' '}
        <b>Cantieri Digitali</b> in modalità acquisizione e/o trasmissione dati.
      </p>
    )}
  />
);

export default DocsIntroduction;
