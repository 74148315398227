import * as React from 'react';


/* --------
 * Component Props
 * -------- */
export interface DocsSectionCodeProps {

}


/* --------
 * Component Definition
 * -------- */
const DocsSectionCode: React.FC<DocsSectionCodeProps> = (props) => {

  const { children } = props;

  return (
    <div className={'docs-section-code'}>
      {children}
    </div>
  );
};

export default DocsSectionCode;
