import * as React from 'react';

import arraySort from 'array-sort';

import type { OpenAPIObject } from 'openapi3-ts';
import { ApiKey } from '../../interfaces/ApiKey';

import OpenAPIDocs from './lib/OpenAPIDocs';
import { textToHash } from './utils/textToHash';


/* --------
 * Internal Types
 * -------- */
type DocsSection = { hash: string, title: string, order: number };


/* --------
 * Define Types
 * -------- */
export interface OpenAPIDocsProviderProps {
  apiKey: ApiKey | null;

  docsObject: OpenAPIObject;
}

export interface OpenAPIDocsContextResult {
  /** Add a section to TOC, and return its unique hash */
  addToGeneralTOC: (title: string, order: number) => string;

  /** The document loaded */
  docs: OpenAPIDocs;

  /** Return ordered list of manual set TOC Section */
  generalTOC: DocsSection[];

  /** Search Value */
  search: string;

  /** Set Search Value */
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}


/* --------
 * Create the Context and exports its Provider
 * -------- */
const OpenAPIDocsContext = React.createContext<OpenAPIDocsContextResult | undefined>(undefined);

export const OpenAPIDocsProvider: React.FC<OpenAPIDocsProviderProps> = (props) => {

  const { children, apiKey, docsObject } = props;

  // ----
  // Internal State
  // ----
  const [ generalTOC, setGeneralTOC ] = React.useState<DocsSection[]>([]);
  const [ search, setSearch ] = React.useState('');


  // ----
  // Define the Docs
  // ----
  const docs = React.useMemo(
    () => new OpenAPIDocs(docsObject, apiKey),
    [ apiKey, docsObject ]
  );


  // ----
  // Helpers
  // ----
  const uniqueHashesPool = new Set<string>();
  const addToGeneralTOC = (title: string, order: number): string => {
    /** Check if an item with same order already exists */
    const sameOrderElement = generalTOC.find((toc) => toc.order === order);

    /** Get the hash */
    const hash = textToHash(title, uniqueHashesPool);

    /** If nothing will change, abort */
    if (sameOrderElement?.title === title && sameOrderElement?.hash === hash) {
      return hash;
    }

    /** Set the new TOC */
    setGeneralTOC([
      ...generalTOC.filter(toc => toc !== sameOrderElement),
      { hash, title, order }
    ]);

    /** Return section hash */
    return hash;
  };


  // ----
  // Context Value
  // ----
  const ctx: OpenAPIDocsContextResult = {
    addToGeneralTOC,
    docs,
    generalTOC: arraySort(generalTOC, [ 'order' ]),
    search,
    setSearch
  };


  // ----
  // Component Render
  // ----
  return (
    <OpenAPIDocsContext.Provider value={ctx}>
      {children}
    </OpenAPIDocsContext.Provider>
  );
};


/* --------
 * Create the Hook
 * -------- */
export function useDocs(): OpenAPIDocsContextResult {
  const ctx = React.useContext(OpenAPIDocsContext);

  if (ctx === undefined) {
    throw new Error('Invalid hook usage');
  }

  return ctx;
}
