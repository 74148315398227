/* --------
 * Create Hash from Text
 * -------- */
function makeUniqueHash(hash: string, uniquePool: Set<string>, iteration: number = 1): string {
  /** Strip number from first iteration */
  const uniqueHash = iteration === 1 ? hash : `${hash}-${iteration}`;

  /** Check if a same hash already exists */
  if (!uniquePool.has(uniqueHash)) {
    uniquePool.add(uniqueHash);
    return uniqueHash;
  }

  /** Continue iteration */
  return makeUniqueHash(hash, uniquePool, iteration + 1);
}


/* --------
 * Main Utils export
 * -------- */
export function textToHash(text: string, uniquePool: Set<string>): string {
  /** Return a unique hash */
  return makeUniqueHash(
    encodeURI(
      text
        // Transform to lowercase
        .toLowerCase()
        // Remove Space
        .replace(/\s/g, '-')
        // Remove HTML if Present
        .replace(/<\/?[^>]+(>|$)/g, '')
        .replace(/=&gt;|&lt;| \/&gt;|<code>|<\/code>|&#39;/g, '')
        // Remove Emoji
        .replace(
          /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])\uFE0F?/g,
          ''
        )
        // Replace Dashes
        .replace(/-+/g, '-')
        .replace(/^-|-$/g, '')
    ),
    uniquePool
  );
}
