import * as React from 'react';

import type { ParameterObject, SchemaObject } from 'openapi3-ts';

import Box from '@appbuckets/react-ui/Box';
import Header from '@appbuckets/react-ui/Header';
import Section from '@appbuckets/react-ui/Section';
import Label from '@appbuckets/react-ui/Label';
import Tabs from '@appbuckets/react-ui/Tabs';
import Table from '@appbuckets/react-ui/Table';

import type { TabPanelsShorthand } from '@appbuckets/react-ui/Tabs';

import { useDocs } from '../docs.context';

import DocsSection from './DocsSection';

import type { TOCElement } from '../organism/DocsToc';


interface EndpointDocsProps {
  /** The method */
  method: string;

  /** The toc element */
  tocElement: TOCElement;
}


const EndpointDocs: React.VFC<EndpointDocsProps> = (props) => {

  const { method, tocElement } = props;

  // ----
  // Internal Hooks
  // ----
  const { docs } = useDocs();

  const endpoint = docs.getEndPoint(tocElement.hash, method.toLowerCase());


  // ----
  // Assert endpoint object exists
  // ----
  if (!endpoint) {
    return null;
  }

  const responses = docs.getResponses(endpoint);
  const bodyContent = docs.getRequestBody(endpoint);


  // ----
  // Render Component
  // ----
  return (
    <DocsSection
      level={3}
      method={method}
      hash={`${method}--${tocElement.hash}`}
      title={tocElement.hash}
      description={(
        <React.Fragment>
          {endpoint.summary && (
            <Box my={6}>
              <Section
                label={'Descrizione'}
                content={(
                  // eslint-disable-next-line react/no-danger
                  <div dangerouslySetInnerHTML={{ __html: endpoint.summary }} />
                )}
              />
            </Box>
          )}

          {Array.isArray(endpoint.parameters) && (
            <Box>
              <Section label={'Parametri della richiesta'}>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={6} header={'Chiave'} />
                      <Table.HeaderCell width={4} header={'Richiesto'} textAlign={'center'} />
                      <Table.HeaderCell width={4} header={'Tipo'} textAlign={'center'} />
                      <Table.HeaderCell width={10} header={'Descrizione'} />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {endpoint.parameters.map((param, ix) => {
                      if (typeof param.$ref !== 'string') {
                        const queryParam = param as ParameterObject;

                        return (
                          <Table.Row key={`${queryParam.name}-${ix}`}>
                            <Table.Cell
                              header={queryParam.name}
                              content={(queryParam.name === 'date' || /(Date)$/.test(queryParam.name)) && 'YYYY-MM-DD'}
                            />
                            <Table.Cell textAlign={'center'}>
                              <Label
                                success={queryParam.required}
                                content={queryParam.required ? 'Si' : 'No'}
                              />
                            </Table.Cell>
                            <Table.Cell textAlign={'center'}>
                              <code>{(queryParam.schema as SchemaObject)?.type}</code>
                            </Table.Cell>
                            <Table.Cell>
                              {queryParam.description}
                            </Table.Cell>
                          </Table.Row>
                        );
                      }

                      return null;
                    })}
                  </Table.Body>
                </Table>
              </Section>
            </Box>
          )}

          {bodyContent && (
            <Box my={6}>
              <Section
                label={'Corpo della Richiesta in application/json'}
                content={(
                  <Tabs
                    panels={[
                      {
                        trigger: 'Esempio',
                        panel  : (
                          <pre>
                            {JSON.stringify(bodyContent.example, null, 2)}
                          </pre>
                        )
                      },
                      {
                        trigger: 'Schema',
                        panel  : (
                          <pre>
                            {JSON.stringify(bodyContent.schema, null, 2)}
                          </pre>
                        )
                      }
                    ]}
                  />
                )}
              />
            </Box>
          )}
        </React.Fragment>
      )}
      code={(
        <React.Fragment>
          {responses.map((response) => {

            let exampleAsString: string = '';
            let schemaAsString: string = '';

            try {
              if (response.example) {
                exampleAsString = JSON.stringify(response.example, null, 2);
              }
            }
            catch (e) {
              window.console.error({ message: 'Error Example Stringify', response, e });
            }

            try {
              schemaAsString = JSON.stringify(response.schema, null, 2);
            }
            catch (e) {
              window.console.error({ message: 'Error Schema Stringify', response, e });
            }

            const tabPanels: TabPanelsShorthand[] = [];

            if (exampleAsString) {
              tabPanels.push({
                trigger: 'Esempio',
                panel  : (
                  <pre>{exampleAsString}</pre>
                )
              });
            }

            if (schemaAsString) {
              tabPanels.push({
                trigger: 'OpenAPI Schema',
                panel  : (
                  <pre>{schemaAsString}</pre>
                )
              });
            }

            return (
              <React.Fragment key={response.statusCode}>
                <Header
                  danger={response.statusCode !== 200}
                  success={response.statusCode === 200}
                  content={`${response.statusCode} - ${response.description}`}
                />

                {(response.example || response.schema) && (
                  <Tabs
                    panels={tabPanels}
                  />
                )}
              </React.Fragment>
            );
          })}
        </React.Fragment>
      )}
    />
  );

};

export default EndpointDocs;
