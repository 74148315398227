import * as React from 'react';


import type { ApiKey } from '../interfaces/ApiKey';


export const ApiKeyContext = React.createContext<ApiKey | undefined>(undefined);

export function useApiKey(): ApiKey {
  const ctx = React.useContext(ApiKeyContext);

  if (ctx === undefined) {
    throw new Error('Invalid hook Usage');
  }

  return ctx;
}
