import * as React from 'react';

import Label from '@appbuckets/react-ui/Label';
import Table from '@appbuckets/react-ui/Table';

import { useApiKey } from '../../../context/apiKey.context';

import DocsSection from '../molecules/DocsSection';


const DocsAuthentication: React.VFC = () => {

  const apiKey = useApiKey();

  return (
    <DocsSection
      tocOrder={3}
      title={'Autenticazione'}
      description={(
        <React.Fragment>
          <p>
            Tutte le chiamate descritte di seguito sono protette con Autenticazione mediante{' '}
            Bearer Token.
          </p>
          <p>
            Una <b>Autenticazione Bearer</b> (o Token Authentication){' '}
            è uno degli schemi di autenticazione HTTP che coinvolge {'l\'utilizzo'}{' '}
            di un token di sicurezza, che deve essere inviato insieme ad ogni richiesta HTTP{' '}
            {'all\'interno'} degli Header della richiesta stessa.
          </p>
          <p>
            Per autenticarti correttamente, aggiungi questo Header alle tue richieste
          </p>
          <div className={'code'}>Authorization: Bearer {'<tuo_token>'}</div>
          <p>Il token che ti è stato assegnato è</p>
          <div className={'code'}>{apiKey.key}</div>
          <p>
            Ti preghiamo di non condividere il tuo token con nessuno, poiché{' '}
            questo è ti è stato generato in maniera univoca e lo consideriamo di fatto{' '}
            non condivisibile a terze parti.
          </p>
          <Table className={'mt-4'}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell header={'Proprietà del Token'} width={8} />
                <Table.HeaderCell header={'Valore'} width={16} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell header={'Attivo'} />
                <Table.Cell>
                  <Label
                    danger={!apiKey.active}
                    success={apiKey.active}
                    content={apiKey.active ? 'Si' : 'No'}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell header={'Nome'} />
                <Table.Cell header={apiKey.name} />
              </Table.Row>
              <Table.Row>
                <Table.Cell header={'Email di Riferimento'} />
                <Table.Cell header={apiKey.email} />
              </Table.Row>
            </Table.Body>
          </Table>
        </React.Fragment>
      )}
    />
  );
};

export default DocsAuthentication;
