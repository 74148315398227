import * as React from 'react';

import { OPENAPI_SPECIFICATION_URL } from '../../../constants';

import DocsSection from '../molecules/DocsSection';


const DocsSpecification: React.VFC = () => (
  <DocsSection
    tocOrder={2}
    title={'Formato OpenAPI'}
    description={(
      <p>
        Questo documento viene redatto automaticamente in formato <b>OpenAPI</b>{' '}
        che viene prodotto dal server API in fase di compilazione, e può quindi ritenersi aggiornato{' '}
        in ogni sua parte.<br />
        Le specifiche OpenAPI complete, redatte dal team di swagger.io sono visibili{' '}
        <a href={OPENAPI_SPECIFICATION_URL} target={'_blank'} rel={'noreferrer'}>qui</a>.
      </p>
    )}
  />
);

export default DocsSpecification;
