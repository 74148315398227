import * as React from 'react';

import { useDocs } from '../docs.context';

import DocsSection from '../molecules/DocsSection';
import EndpointDocs from '../molecules/EndpointDocs';


const DocsApi: React.VFC = () => {

  const { docs } = useDocs();

  const sections = docs.getTocSections();


  // ----
  // Describe Sections
  // ----
  return (
    <React.Fragment>
      {sections.map((section) => (
        <React.Fragment key={section.label}>
          <DocsSection
            key={section.label}
            title={section.label}
            description={section.description && (
              // eslint-disable-next-line react/no-danger
              <div dangerouslySetInnerHTML={{ __html: section.description }} />
            )}
          />
          {section.elements.map((element) => (
            <React.Fragment key={element.hash}>
              <DocsSection
                level={2}
                title={element.title}
              />

              {[ 'GET', 'POST', 'PUT', 'DELETE' ].map((method) => {
                if (element.methods.indexOf(method) === -1) {
                  return null;
                }

                return (
                  <EndpointDocs
                    key={method}
                    method={method}
                    tocElement={element}
                  />
                );
              })}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </React.Fragment>
  );

};

export default DocsApi;
