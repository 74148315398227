import * as React from 'react';
import clsx from 'clsx';

import axios from 'axios';

import * as qs from 'qs';
import OtpInput from 'react-otp-input';

import Box from '@appbuckets/react-ui/Box';
import Container from '@appbuckets/react-ui/Container';
import Divider from '@appbuckets/react-ui/Divider';
import Header from '@appbuckets/react-ui/Header';
import Loader from '@appbuckets/react-ui/Loader';
import Panel from '@appbuckets/react-ui/Panel';

import { API_HOST, ASSETS_URL } from '../constants';

import type { ApiKey } from '../interfaces/ApiKey';


/* --------
 * Internal Status
 * -------- */
type FetchingStatus = 'waiting' | 'fetching' | 'success' | 'error';


/* --------
 * Component Interface
 * -------- */
interface DocsLoginProps {
  /** Handler called once ApiKey has been successfully loaded */
  onApiKeyLoaded?: (apiKey: ApiKey) => void;
}


/* --------
 * Component Definition
 * -------- */
const DocsLogin: React.VFC<DocsLoginProps> = (props) => {

  const { onApiKeyLoaded } = props;

  const query = qs.parse(window.location.search.slice(1));

  // ----
  // Internal State
  // ----
  const otpRef = React.useRef<OtpInput>(null);
  const [ optFetching, setOptFetching ] = React.useState<FetchingStatus>('waiting');
  const [ otpValue, setOtpValue ] = React.useState('');


  // ----
  // Handlers
  // ----
  const handleOtpChange = React.useCallback(
    (newValue: string) => {
      setOptFetching('waiting');
      setOtpValue(newValue);
    },
    []
  );


  // ----
  // Use an Effect to Load Data
  // ----

  React.useEffect(
    () => {
      if (otpValue.length === 6) {
        setOptFetching('fetching');
        const { current } = otpRef;

        setTimeout(() => {
          /** Try to load Data */
          const params = {
            params: {
              guid: query.key,
              pin : otpValue
            }
          };

          axios.get<ApiKey>(`${API_HOST}/v1/apikeys/details`, params)
            .then((response) => {
              if (response.data) {
                if (typeof onApiKeyLoaded === 'function') {
                  onApiKeyLoaded(response.data);
                }
              }
              else {
                current?.focusInput(0);
                setOptFetching('error');
                setOtpValue('');
              }
            })
            .catch(() => {
              current?.focusInput(0);
              setOptFetching('error');
              setOtpValue('');
            });
        }, 1500);
      }
    },
    [ onApiKeyLoaded, otpValue, query.key ]
  );


  // ----
  // Build Input Class
  // ----
  const isInputDisabled = optFetching === 'fetching' || optFetching === 'success';
  const inputClasses = clsx(
    'text is-big',
    optFetching === 'fetching' && 'is-primary',
    optFetching === 'error' && 'is-danger',
    optFetching === 'success' && 'is-success',
    isInputDisabled && 'disabled'
  );


  // ----
  // Component Render
  // ----
  return (
    <Container fixedTo={'phone'}>
      <Box pt={8} py={4}>
        <Panel solid textAlign={'center'}>
          <Panel.Header>
            <Box py={4}>
              <img
                alt={'Proedis Logo'}
                src={`${ASSETS_URL}/square_128.png`}
                height={64}
                width={64}
              />
            </Box>
          </Panel.Header>
          <Panel.Body>
            <Header
              textAlign={'center'}
              content={'YardTalker'}
              subheader={'Scopri le nostre API'}
            />
            <Box py={4}>
              Inserisci il PIN relativo alla tua Chiave API per scoprirne{' '}
              come utilizzare questo strumento sviluppato da <b>Proedis</b>
            </Box>
            <Box py={4}>
              <OtpInput
                ref={otpRef}
                shouldAutoFocus={true}
                isDisabled={isInputDisabled}
                containerStyle={'otp-container'}
                focusStyle={'is-focused'}
                inputStyle={inputClasses}
                numInputs={6}
                value={otpValue}
                onChange={handleOtpChange}
              />
            </Box>

            <Divider />

            <Box pb={4}>
              Se non hai ricevuto le tue credenziali,<br />
              oppure se hai problemi ad accedere <a href={'mailto:info@proedis.net'}>contattaci</a>
            </Box>

            {optFetching === 'fetching' && (
              <Loader active appearance={'primary'} type={'indeterminate bar'} />
            )}
          </Panel.Body>
          <Panel.Footer>
            <Box textColor={'white'}>
              <p>
                All rights reserved - © Copyright {new Date().getFullYear()}
              </p>
              <p className={'mt-2'}>
                <a href={'https://proedis.net'} target={'_blank'} rel={'noreferrer'}>Proedis SRL</a>
              </p>
            </Box>
          </Panel.Footer>
        </Panel>
      </Box>
    </Container>
  );

};

export default DocsLogin;
